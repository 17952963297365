<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  // watch: {
  //   chartData (newData, oldData) {
  //     this.chartData = newData
  //     this.renderChart(this.chartData, this.options)
  //   }
  // },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style lang="scss" scoped>

</style>
